import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import ArrowForwardIosRounded from "@material-ui/icons/ArrowForwardIosRounded";
import { Button, Dialog, Divider, TextField, Typography } from "@mui/material";
import useStyles from "./styles";
import AddIcon from "@material-ui/icons/Add";
import BlockIcon from "@material-ui/icons/Block";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Calendar from "../common/Calendar";
import Week from "../common/Week";
import { convertMonth, convertDayOfWeek } from "utils";
import EmojiFlagsIcon from "@material-ui/icons/EmojiFlags";
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";
import { getAuth } from "firebase/auth";
import ArrowBackIosRounded from "@material-ui/icons/ArrowBackIosRounded";
import SpinnerLoading from "../common/spinnerLoading/SpinnerLoading";
import { Call, Chat, MailOutline, QueryBuilder, WhatsApp, CalendarToday, Logout } from "@material-ui/icons";
import ProfessionalSelect from "../common/ProfessionalSelect";
import ReservationCard from "../common/ReservationCard";
import NotificationCard from "../common/NotificationCard";
import ReservationListItem from "../common/ReservationListItem";
import ReservationDetailsDialog from "../common/ReservationDetailsDialog";
import { TbLogout } from "react-icons/tb";
import api from "../../services/api";
import AppDownloadRedirectDialog from "../common/AppDownloadRedirectDialog";
import payout from "../../services/payout";
import ButtonCommom from "../common/Button";
import { useMixpanel } from "react-mixpanel-browser";

const Schedule = (props) => {
	const [auth, setAuth] = useState(getAuth());

	const classes = useStyles();
	const navigate = useNavigate();
	const locate = useLocation();
	const mixpanel = useMixpanel();
	const [update, setUpdate] = useState(false);

	const [professionals, setProfessionals] = useState([]);
	const [hours, setHours] = useState([]);
	const [now, setNow] = useState(new Date(Date.now()));
	const [date, setDate] = useState(new Date(Date.now()));
	const [showCalendar, setShowCalendar] = useState(false);
	const [notifications, setNotifications] = useState([]);
	const [showNotifications, setShowNotifications] = useState(false);
	const [business, setBusiness] = useState(null);
	const [loading, setLoading] = useState(false);
	const [newsCount, setNewsCount] = useState(0);
	const [lastReminderCheckDate, setLastReminderCheckDate] = useState(new Date(0));
	const [reservations, setReservations] = useState([]);
	const [reservationDates, setReservationDates] = useState([]);
	const [selectedBooking, setSelectedBooking] = useState(null);
	const [openDescripition, setOpenDescription] = useState(false);
	const [selectedBookingTransaction, setSelectedBookingTransaction] = useState(null);
	const [selectedPaymentList, setSelectedPaymentList] = useState(null);
	const [filtredProfessional, setFiltredProfessional] = useState(null);
	const [reservationsLoading, setReservationsLoading] = useState(true);
	const [paymentLoading, setPaymentLoading] = useState(false);
	const [transactionLoading, setTransactionLoading] = useState(false);
	const [bookingDates, setBookingDates] = useState([]);
	const [listView, setListView] = useState(false);
	const [openAppRedirectModal, setOpenAppRedirectModal] = useState(false);
	const [AppRedirectMessage, setAppRedirectMessage] = useState("");
	const [myProfessionalId, setMyProfessionalId] = useState(null);
	const [openFreeTest, setOpenFreeTest] = useState(false);
	const [reaminingFreeTestDays, setReaminingFreeTestDays] = useState(0);

	useEffect(() => {
		window.addEventListener("resize", (e) => {
			setUpdate(!update);
		});
	}, []);

	const getMyProfessionalId = (me, professionals, business) => {
		professionals.forEach((professional) => {
			if (
				me.id != business.ownerId &&
				professional.phone == me.phone &&
				(professional.permission == "owner" || professional.permission == "manager")
			) {
				setMyProfessionalId(professional.id);
			}
		});
	};

	useEffect(() => {
		if (business) {
			api
				.get(`subscription?business_id=${business.id}`)
				.then((res) => {
					let finalFreeTestData = new Date(business.createdAt);
					finalFreeTestData.setDate(finalFreeTestData.getDate() + 7);
					if (finalFreeTestData.getTime() > Date.now()) {
						setReaminingFreeTestDays((Math.floor((finalFreeTestData.getTime()-Date.now())/86400000)));
						setOpenFreeTest(true);
					}
					if (finalFreeTestData.getTime() <= Date.now() && new Date(res.data[0].expiration).getTime() <= Date.now()) {
						navigate("/expired");
					}
				})
				.catch((err) => {
					//console.log(err);
				});
			api
				.get("feed?page=1&per_page=20")
				.then((res) => {
					setNotifications(res.data);
				})
				.catch((err) => {
					//console.log(err);
				});
		}
	}, [business]);

	useEffect(() => {
		let arr = [];
		for (var i = 0; i < 24; i++) {
			arr.push(i);
		}
		setHours(arr);
	}, []);

	setTimeout(() => {
		setInterval(() => {
			setNow(new Date(Date.now()));
		}, 60000);
	}, now.getSeconds() * 1000);

	useEffect(() => {
		let lastCheck = localStorage.getItem("@prit-web/lastReminderCheck");
		if (lastCheck !== null) {
			setLastReminderCheckDate(new Date(lastCheck));
			let count = 0;
			notifications.forEach((element) => {
				if (lastReminderCheckDate < new Date(element.createdAt)) {
					count++;
				}
			});
			setNewsCount(count);
		}
	}, []);

	useEffect(() => {
		let count = 0;
		if (notifications) {
			notifications.forEach((element) => {
				if (lastReminderCheckDate < new Date(element.createdAt)) {
					count++;
				}
			});
			setNewsCount(count);
		}
	}, [notifications, lastReminderCheckDate]);

	const handleCheckAllReminders = () => {
		localStorage.setItem("@prit-web/lastReminderCheck", Date.now());
		setLastReminderCheckDate(Date.now());
		setNewsCount(0);
	};

	const dateFormat = (date) => {
		date = new Date(date);
		return date.getFullYear() + "-" + date.getMonth() + 1 + "-" + date.getDate();
	};

	useEffect(() => {
		const request = setInterval(() => {
			setLoading(true);
			if (auth?.currentUser) {
				api
				.get(
					"schedule/?is_message_bookings=false&is_payment_types=false&is_message_clients=true&is_sectors=false&is_pricing_type=false"
				)
					.then((res) => {
						if (!res.data.business) {
							setLoading(false);
							setBusiness(null);
							setAppRedirectMessage(
								"Parece que você ainda não possui um estabelecimento... Baixe agora o Prit para Profissionais e crie um novo estabelecimento para você!"
							);
							setOpenAppRedirectModal(true);
						} else {
							setBusiness(res.data.business);
							setProfessionals(res.data.professionals);
						}
						setReservationDates(res.data.bookingDates);
						setReservationsLoading(false);
						setReservations(res.data.bookings);
						setLoading(false);
						getMyProfessionalId(res.data.me, res.data.professionals, res.data.business);
						clearInterval(request);
					})
					.catch((err) => {
						setAppRedirectMessage(
							"Parece que estamos com problemas técnicos... Baixe agora o Prit para Profissionais continue agendando comseus clientes!"
						);
						setOpenAppRedirectModal(true);
						setLoading(false);
						setReservationsLoading(false);
						//console.log(err);
					});
			} else {
				setAuth(getAuth());
			}
		}, 1000);
	}, []);

	useEffect(() => {
		setReservationsLoading(true);
	}, [date]);

	useEffect(() => {
		if (business?.id) {
			api
				.get(
					"schedule/bookings/?business_id=" +
					business?.id +
					"&start_time=" +
					date.toISOString() +
					"&end_time=" +
					new Date(date.getTime() + 86400000).toISOString()
				)
				.then((res) => {
					setReservations(res.data.bookings);
					setReservationsLoading(false);
					setLoading(false);
				})
				.catch((err) => {
					setAppRedirectMessage(
						"Parece que estamos com problemas técnicos... Baixe agora o Prit para Profissionais continue agendando comseus clientes!"
					);
					setOpenAppRedirectModal(true);
					setReservationsLoading(false);
					setLoading(false);
					//console.log(err);
				});
		}
	}, [date]);

	useEffect(() => {

		if (selectedBooking?.bookingTransactionId) {
			api
				.get("booking_transaction/" + selectedBooking?.bookingTransactionId)
				.then((res) => {
					setSelectedBookingTransaction(res.data);
					setTransactionLoading(false);
				})
				.catch((err) => {
					setAppRedirectMessage(
						"Parece que estamos com problemas técnicos... Baixe agora o Prit para Profissionais continue agendando comseus clientes!"
					);
					setOpenAppRedirectModal(true);
					setTransactionLoading(false);
					//console.log(err);
				});
		}
		if (selectedBooking?.bookingTransactionId) {
			payout
				.get(
					"api/v1/payment/list/grouped/payments/" +
					selectedBooking?.bookingTransactionId
				)
				.then((res) => {
					setSelectedPaymentList(res.data);
					setPaymentLoading(false);
				})
				.catch((err) => {
					setAppRedirectMessage(
						"Parece que estamos com problemas técnicos... Baixe agora o Prit para Profissionais continue agendando comseus clientes!"
					);
					setOpenAppRedirectModal(true);
					setPaymentLoading(false);
					//console.log(err);
				});
		}
	}, [selectedBooking, transactionLoading, paymentLoading]);

	useEffect(() => {
		if (selectedBooking && !transactionLoading && !paymentLoading) {
			setOpenDescription(true);
		}
	}, [transactionLoading, paymentLoading]);

	return (
		<div style={{ display: "flex" }}>
			<SpinnerLoading loading={loading || reservationsLoading}></SpinnerLoading>

			<AppDownloadRedirectDialog open={openAppRedirectModal} setOpen={setOpenAppRedirectModal} message={AppRedirectMessage} />

			<ReservationDetailsDialog
				business={business}
				open={openDescripition}
				setOpen={setOpenDescription}
				booking={selectedBooking}
				bookingTransaction={selectedBookingTransaction}
				paymentList={selectedPaymentList}
			/>

			<Dialog
				open={openFreeTest}
				PaperProps={{
					style: { borderRadius: 20, boxShadow: "0px 0px 20px #888888",width:"30vw" },
				}}>
				<div>
					<CloseIcon
						onClick={() => {
							setOpenFreeTest(false);
						}}
						style={{
							width: "calc(2vw + 10px)",
							height: "calc(2vw + 10px)",
							cursor: "pointer",
							color: "gray",
							marginTop: "20px",
							marginLeft: "88%",
						}}></CloseIcon>
				</div>
				<div style={{ display: "flex", justifyContent: "center" }}>
					<div style={{ margin: "1vh" }}>
						<div style={{ display: "flex", justifyContent: "center",width:"20vw" }}>
							<Typography style={{ fontSize: "calc(0.5vh + 2px)", textAlign: "center" }}>
								<strong className={classes.title}>{"Você tem "+(reaminingFreeTestDays>1?reaminingFreeTestDays+" dias":"apenas mais 1 dia")+" de teste gratuito do Prit Business"}</strong>
							</Typography>
						</div>
						<div style={{ display: "flex", justifyContent: "center" }}>
							<Typography style={{ marginTop: "calc(3vh + 10px)", width: "20vw", textAlign: "center" }}>
								<strong className={classes.message} style={{ fontSize: "calc(1.5vh + 5px)" }}>
									Atualmente você está usando a versão gratuita do PRIT.Veja a nossa oferta e faça sua empresa crescer com
									disponibilidade e gerenciamento da sua agenda e do seu negócio 24 horas por dia,através do PRIT.
								</strong>
							</Typography>
						</div>
						<div style={{ display: "flex", justifyContent: "center" }}>
							<Typography style={{ marginTop: "calc(3vh + 10px)", width: "20vw", textAlign: "center" }}>
								<strong className={classes.message} style={{ fontSize: "calc(1.5vh + 5px)" }}>
									Sem custos ocultos ou surpresas desagradáveis.Cancele quando quiser, não temos fidelidade.
								</strong>
							</Typography>
						</div>

						<div style={{ display: "flex", justifyContent: "center", marginTop: "1vw" }}>
							<ButtonCommom
								style={{ textTransform: "none" }}
								text={"Saiba Mais"}
								params={{}}
								onClick={() => {
									setAppRedirectMessage("Baixe agora o Prit para Profissionais em seu celular e assine seu plano!");
									setOpenAppRedirectModal(true);
								}}
							/>
						</div>
					</div>
				</div>
			</Dialog>

			<div style={{ width: showNotifications ? "70%" : "100%", overflow: "hidden" }}>
				<div
					style={{
						overflow: "hidden",
						background: "#eeeeee",
					}}>
					<div
						style={{
							overflow: "hidden",
							justifyContent: "center",
							transition: "2s",
							height: showCalendar ? "35vw" : "0px",
							alignItems: "center",
						}}></div>
				</div>
				<Divider></Divider>

				<div
					style={{
						padding: "1vw",
						display: "flex",
						justifyContent: "space-evenly",
						alignItens: "center",
					}}>
					<div style={{ marginLeft: "auto", marginRight: "1vw" }}>
						{listView ? (
							<CalendarToday
								onClick={() => setListView(!listView)}
								style={{
									color: "#979797",
									width: "calc(0.8vw + 20px)",
									height: "calc(0.8vw + 20px)",
									cursor: "pointer",
								}}></CalendarToday>
						) : (
							<FormatListBulletedIcon
								onClick={() => setListView(!listView)}
								style={{
									color: "#979797",
									width: "calc(1vw + 20px)",
									height: "calc(1vw + 20px)",
									cursor: "pointer",
								}}></FormatListBulletedIcon>
						)}
						<NotificationsNoneIcon
							onClick={() => setShowNotifications(!showNotifications)}
							style={{
								color: "#979797",
								width: "calc(1vw + 20px)",
								height: "calc(1vw + 20px)",
								cursor: "pointer",
								margin: "0 3vw",
							}}></NotificationsNoneIcon>

						<TbLogout
							onClick={() => navigate("/login", { business} )}
							style={{
								color: "#979797",
								width: "calc(1vw + 20px)",
								height: "calc(1vw + 20px)",
								cursor: "pointer",
							}}></TbLogout>
					</div>
				</div>

				<Divider></Divider>

				<div style={{ display: "flex" }}>
					<div
						style={{
							width: "25vw",
							paddingTop: "5vh",
							position: "fixed",
						}}>
						<div style={{ display: "flex", justifyContent: "center" }}>
							<div
								style={{
									width: "18vw",
									height: "19vw",
									borderRadius: "30px",
									padding: "10px",
									boxShadow: "0px 0px 20px #888888",
								}}>
								<Calendar
									update
									style={{ transform: "scale(" + window.innerWidth / 6000 + ")", transformOrigin: "top center" }}
									date={date}
									onChange={() => {
										setShowCalendar(false);
									}}
									setDate={setDate}
									daysToNotify={reservationDates}></Calendar>
							</div>
						</div>
						<div style={{ display: "flex", justifyContent: "center", marginTop: "5vh" }}>
							{/* <BlockIcon
						style={{
							color: "#434343",
							backgroundColor: "white",
							borderRadius: "100%",
							width: "3vw",
							height: "3vw",
							minWidth: "30px",
							minHeight: "30px",
							boxShadow: "0px 0px 30px #888888",
							zIndex: "1",
							marginBottom: "calc(2vw + 5px)",
							alignSelf: "right",
						}}></BlockIcon> */}

							<Button
								onClick={() => {
									navigate("/reservation/new", { state: { myProfessionalId: myProfessionalId } });
								}}
								style={{
									color: "white",
									backgroundColor: "#3689ea",
									borderRadius: 30,
									boxShadow: "0px 0px 30px #888888",
									cursor: "pointer",
									textTransform: "none",
									fontSize: "1.5vw",
									padding: "0.5vw 1vw",
								}}>
								+ Novo agendamento
							</Button>

							{/* <Button
								onClick={() => {
									navigate("/block", { state: { myProfessionalId: myProfessionalId } });
								}}
								style={{
									color: "white",
									backgroundColor: "#3689ea",
									borderRadius: 30,
									boxShadow: "0px 0px 30px #888888",
									cursor: "pointer",
									textTransform: "none",
									fontSize: "1.5vw",
								}}>
								Bloquear horário
							</Button> */}
						</div>
					</div>
					<Divider style={{ marginLeft: "25vw" }} flexItem orientation="vertical"></Divider>
					<div style={{ flexGrow: 1 }}>
						<div style={{ padding: "30px", backgroundColor: "#eeeeee", width: "100%" }}>
							<Typography
								style={{
									color: "rgb(54, 137, 234)",
									fontSize: "calc(1vw + 10px)",
									marginLeft: "1vw",
									marginRight: "auto",
								}}>
								{convertDayOfWeek(date.getDay()) +
									", " +
									date.getDate() +
									" de " +
									convertMonth(date.getMonth()) +
									" de " +
									date.getFullYear()}
							</Typography>
						</div>
						{professionals?.length > 0 && (
							<>
								<div style={{ padding: "30px", display: "flex" }}>
									<div
										onClick={() => {
											setFiltredProfessional(null);
										}}
										style={{padding:"1vw"}}>
										<AccountCircleIcon
											style={{
												color: "darkgray",
												width: "calc(3vw + 20px)",
												height: "calc(3vw + 20px)",
												margin: "10px",
												cursor: "pointer",
												borderRadius: "100%",
												boxShadow: filtredProfessional ? "none" : "0px 0px 20px 10px #c2dbf8",
											}}></AccountCircleIcon>
										<Typography
											style={{
												fontSize: "calc(0.5vw + 10px)",
												color: "gray",
												textAlign: "center",
											}}>
											Todos
										</Typography>
									</div>

									<Divider
										orientation="vertical"
										flexItem
										style={{
											margin: "0 calc(1vw + 5px)",
										}}></Divider>
									<div style={{display:"flex",overflowX:"scroll",width:"60vw",padding:"1vw"}}>
										{professionals.map((professional) => { 
											return (
												<ProfessionalSelect
													name={professional.name}
													onClick={() => {
														setFiltredProfessional(professional);
													}}
													selected={filtredProfessional?.id == professional?.id}></ProfessionalSelect>
											);
										})}
									</div>
								</div>
							</>
						)}
						{listView ? (
							<div>
								{reservations &&
									reservations.map((booking) => {
										const { startTime, professional, service, client } = booking;
										const bookingDate = new Date(startTime);
										if (filtredProfessional == null || professional.id == filtredProfessional.id) {
											if (
												bookingDate.getDate() == date.getDate() &&
												bookingDate.getMonth() == date.getMonth() &&
												bookingDate.getFullYear() == date.getFullYear()
											) {
												return (
													<ReservationListItem
													business={business}
														booking={booking}
														bookingDate={bookingDate}
														onClick={() => {
															setSelectedBooking(booking);
															setPaymentLoading(true);
															setTransactionLoading(true);
														}}
													/>
												);
											}
										}
									})}
								{reservations.length == 0 && (
									<Typography
										style={{
											fontSize: "calc(2.5vw + 5px)",
											textAlign: "center",
											color: "gray",
											marginTop: "15vh",
											alignSelf: "center",
										}}>
										Não existem reservas para a data selecionada
									</Typography>
								)}
							</div>
						) : (
							<>
								<div style={{ paddingLeft: "30px" }}>
									{hours.map((hour) => {
										return (
											<div style={{ padding: "30px", height: "60px" }}>
												{date.getDate() == now.getDate() &&
													((now.getHours() === hour && now.getMinutes() <= 30) ||
														(now.getHours() === hour - 1 && now.getMinutes() > 30)) ? (
													<Typography
														style={{
															fontSize: "14px",
															color: "darkgray",
															position: "relative",
															top: now.getHours() === hour ? now.getMinutes() : now.getMinutes() - 60 + "px",
															padding: 0,
															margin: 0,
															color: "red",
														}}>
														{(now.getHours() < 10 ? "0" + now.getHours() : now.getHours()) +
															":" +
															(now.getMinutes() < 10 ? "0" + now.getMinutes() : now.getMinutes())}
														<Divider
															style={{
																backgroundColor: "red",
																position: "relative",
																left: "50px",
																bottom: "10px",
																width: "98%",
															}}></Divider>
													</Typography>
												) : (
													<Typography style={{ fontSize: "14px", color: "darkgray", padding: 0, margin: 0 }}>
														{(hour < 10 ? "0" + hour : hour) + ":00"}
													</Typography>
												)}
												<div style={{ display: "flex" }}>
													{reservations &&
														reservations.map((booking) => {
															const { startTime, professional, service, client } = booking;
															const bookingDate = new Date(startTime);
															

															if (filtredProfessional == null || professional.id == filtredProfessional.id) {
																if (
																	bookingDate.getDate() == date.getDate() &&
																	bookingDate.getMonth() == date.getMonth() &&
																	bookingDate.getFullYear() == date.getFullYear()
																) {
																	if (
																		(bookingDate?.getHours() === hour && bookingDate?.getMinutes() <= 30) ||
																		(bookingDate?.getHours() === hour - 1 && bookingDate?.getMinutes() > 30)
																	) {
																		if(booking.status !== "cancelled" && booking.status !== "inactive"){
																			return (
																			<ReservationCard
																				clientName={client?.name}
																				serviceName={service?.name}
																				professionalName={professional?.name}
																				onClick={() => {
																					setSelectedBooking(booking);
																					setPaymentLoading(true);
																					setTransactionLoading(true);
																				}}
																				color={professional?.color}
																			/>
																		);
																		}
																		
																	}
																}
															}
															return <></>;
														})}
												</div>
											</div>
										);
									})}
								</div>
							</>
						)}
					</div>
				</div>
			</div>
			<div
				style={{
					boxShadow: "0px 0px 5px #888888",
					transition: "0.1s",
					width: showNotifications ? "30%" : "0",
					minWidth: showNotifications ? "200px" : "0",
					justifyContent: "left",
					overflow: "hidden",
					display: "flex",
					position: "sticky",
					top: 0,
					height: "100vh",
				}}>
				<div
					style={{
						transition: showNotifications ? "1s" : "0.1s",
						opacity: showNotifications ? "100%" : "0",
						width: "1000px",
					}}>
					<div style={{ display: "flex" }}>
						<ArrowForwardIosRounded
							style={{
								color: "#979797",
								width: "calc(1vw + 20px)",
								height: "calc(1vw + 20px)",
								cursor: "pointer",
								padding: "1vw 1.5vw",
							}}
							onClick={() => {
								setShowNotifications(false);
							}}
						/>
						<Typography
							style={{
								fontSize: "calc(2vw + 5px)",
								textAlign: "center",
								color: "gray",
								margin: "9.5px 0",
								width: "calc(100% - 8vw)",
							}}>
							Notificações
						</Typography>
					</div>
					<Divider></Divider>
					<div style={{ display: "flex", flexDirection: "row", padding: "5px", alignItems: "center" }}>
						<div style={{ display: "flex", flexDirection: "row", padding: "5px", margin: "10px 0" }}>
							<Typography
								style={{ fontWeight: "bold", color: "lightgray", marginLeft: "10px", fontSize: "calc(0.5vw + 5px)" }}>
								Novas
							</Typography>
							<Typography
								style={{
									color: "#3689ea",
									backgroundColor: "#D6EAF8",
									fontWeight: "bold",
									borderRadius: "30px",
									textAlign: "center",
									padding: "0 5px",
									marginLeft: "10px",
									fontSize: "calc(0.5vw + 5px)",
								}}>
								{newsCount}
							</Typography>
						</div>
						<Button
							style={{
								marginRight: 5,
								marginLeft: "auto",
								color: "#3689ea",
								textTransform: "none",
								boxShadow: "0px 0px 5px #888888",
								height: "5vw",
								maxHeight: "40px",
								fontSize: "calc(0.5vw + 5px)",
							}}
							onClick={handleCheckAllReminders}>
							Marcar tudo como lida
						</Button>
					</div>
					<Divider></Divider>
					<div
						style={{
							display: "block",
							overflowY: "scroll",
							scrollBehavior: "smooth",
							height: "86vh",
							direction: "rtl",
							backgroundColor: "#ffffff",
							marginLeft: "2px",
						}}>
						<div style={{ direction: "ltr" }}>
							{notifications?.length !== 0 && notifications != null
								? notifications.map((notifcation) => (
									<NotificationCard
										notifcation={notifcation}
										onClick={() => {
											setSelectedBooking(notifcation);
											setPaymentLoading(true);
											setTransactionLoading(true);
										}}
										lastReminderCheckDate={lastReminderCheckDate}
									/>
								))
								: !loading && (
									<Typography
										style={{
											paddingTop: "50px",
											color: "lightgray",
											textAlign: "center",
											fontSize: "calc(0.8vw + 5px)",
										}}>
										Você não tem notificações
									</Typography>
								)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Schedule;
